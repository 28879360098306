import { Get, getAPIUrl, Post } from "../../../Apiservices/ApiMethods";
import { TicketEndpoint } from "./TicketEndpoint";

export const getOptionType = (param = "") => {
  const url = getAPIUrl(TicketEndpoint.optionTypes, param);
  return Get(url, param);
};

export const getUsersList = (param = '') => {
  const url = getAPIUrl(TicketEndpoint.usersList, param)
  return Get(url, param)
}
export const userAssignedMail = (data, param = '') => {
  const url = getAPIUrl(TicketEndpoint.userAssignedMail, param)
  return Post(url, data)
}

export const ChangeTicketDetail = (data, param = '') => {
  const url = getAPIUrl(TicketEndpoint.changeTicketDetail, param)
  return Post(url, data)
}

export const ChangeTicketAssigneeDetail = (data, param = '') => {
  const url = getAPIUrl(TicketEndpoint.changeTicketAssignee, param)
  return Post(url, data)
}