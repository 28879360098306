import { FaRegUserCircle } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useCommmonStore } from "../Store/CommonReducer";
import { HiOutlineTicket } from "react-icons/hi";
import { RiContactsBook2Line } from "react-icons/ri";
import { IoSettingsOutline } from "react-icons/io5";
import { getLocalStorageItem } from "../Utils";
import { GoTag } from "react-icons/go";

export const MenuItems = () => {
  const { selected } = useCommmonStore();
  const list = [
    {
      key: "1",
      title:null,
      icon: (
        <MdDashboard size={17} color={selected === "1" ? "var(--secondary)" : "black"} />
      ),
      label: (
        <NavLink to={"/dashboard"}>
          <p>Dashboard</p>
        </NavLink>
      ),
    },
    {
      key: "2",
      title:null,
      icon: (
        <FaRegUserCircle size={17}
          color={selected === "2" ? "var(--secondary)" : "black"}
        />
      ),
      label: (
        <NavLink to={"/profile"}>
          <p>Profile</p>
        </NavLink>
      ),
    },
    {
      key: "3",
      title:null,
      icon: (
        <HiOutlineTicket size={17}
          color={selected === "3" ? "var(--secondary)" : "black"}
        />
      ),
      label: (
        <NavLink to={"/tickets"}>
          <p>Ticket</p>
        </NavLink>
      ),
    },
    {
      key: "4",
      title:null,
      icon: (
        <RiContactsBook2Line size={17}
          color={selected === "4" ? "var(--secondary)" : "black"}
        />
      ),
      label: (
        <NavLink to={"/contacts"}>
          <p>Contacts</p>
        </NavLink>
      ),
    },
    {
      key: "5",
      title:null,
      icon: (
        <GoTag size={17}
          color={selected === "5" ? "var(--secondary)" : "black"}
        />
      ),
      label: (
        <NavLink to={"/vendor/plans"}>
          <p>Plans</p>
        </NavLink>
      ),
    },
    {
      key: "6",
      title:null,
      icon: (
        <IoSettingsOutline size={17}
          color={selected === "6" ? "var(--secondary)" : "black"}
        />
      ),
      label: (
        <NavLink to={"/vendor/settings"}>
          <p>Setting</p>
        </NavLink>
      ),
    },
  ];
  if (getLocalStorageItem("Role") === "user") {
    let data = [...list];
    data = data.filter((i) => i.key === "1" || i.key === "2"|| i.key === "3" || i.key === "4");
    return data;
  } else {
    return list;
  }
};
