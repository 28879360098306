import { lazy } from "react";
import ProtectedAuth from "./PrivateAuth";
const Dashboard = lazy(() => import("../Views/User/Dashboard/Dashboard"));
const Contacts = lazy(() => import("../Views/User/Contacts/Contacts"));
const Ticket = lazy(() => import("../Views/User/Tickets/Ticket"));
const NewEmail = lazy(() => import("../CommonComponents/NewEmail"));
const Profile = lazy(() => import("../Views/User/Profile/Profile"));
const ContactDetail = lazy(() =>
  import("../Views/User/Contacts/ContactDetail")
);
const OpenTicket = lazy(() => import("../Views/User/OpenTicket/OpenTicket"));

export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/Dashboard",
          element: <Dashboard />,
        },
        {
          path: "/tickets",
          element: <Ticket />,
        },
        {
          path: "/openTicket",
          element: <OpenTicket />,
        },
        {
          path: "/contacts",
          element: <Contacts />,
        },
        {
          path: "/Contact-details",
          element: <ContactDetail />,
        },
        {
          path: "/new-email",
          element: <NewEmail />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        // ERROR ROUTES ENDED
      ],
    },
  ];
};
