import newticket from "../Assets/newticket.png";
import openticket from "../Assets/openticket.png";
import pendingticket from "../Assets/pendingticket.png";
import onholdticket from "../Assets/Onhold.png";
import resolvedticket from "../Assets/resolved.png";
import closedticket from "../Assets/closed.png";
import manThinking from "../Assets/man-thinking.png";
import websiteLogo from "../Assets/web-logo.png";
import smallLogo from "../Assets/small-logo.png";

import dummyuser from "../Assets/dummyuser.png"
export const Images = {
  newticket,
  openticket,
  pendingticket,
  onholdticket,
  resolvedticket,
  closedticket,
  dummyuser,manThinking,websiteLogo,smallLogo
};
