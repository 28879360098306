import { Button, Drawer, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import CommonInput from "./CommonInput";
import {
  dispatchtoast,
  emailPattern,
  getLocalStorageItem,
  makeLabel,
  mobilePattern,
} from "../Utils";
import { createContact, editContact } from "../Apiservices/CommonFun";
import { getOptionType } from "../Views/User/Tickets/Ticketfun";

const NewConatct = ({
  openDrawer,
  setOpen,
  type,
  fillData,
  apiCall = () => {},
}) => {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [tagss, setTags] = useState([]);
  const { name, email_id, title, about, phone, address, tags } = fillData || {};
  const getTags = () => {
    getOptionType(
      `tags&vendorId=${getLocalStorageItem("userDetails")?.vendorId}`
    )
      .then((res) => {
        if (res.status)
          setTags(
            res?.data.map((i) => {
              return {
                label: i,
                value: i,
              };
            })
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTags();
  }, []);
  const handleSubmit = async (val) => {
    setBtnLoading(true);
    try {
      let res;
      if (type === "edit") {
        res = await editContact(val, `&email=${fillData?.email_id}`);
      } else {
        res = await createContact(val);
      }
      if (res.status) {
        dispatchtoast(res.message);
        setOpen(false);
        if (type === "edit") {
          apiCall();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBtnLoading(false);
    }
  };
  useEffect(() => {
    if (type === "edit") {
      form.setFieldsValue({
        name,
        about,
        tags, // Make sure the name matches the one used in the Form.Item
        address,
        phone: phone ,
        email:email_id,
        title,
      });
    }
    //eslint-disable-next-line
  }, []);
  return (
    <Drawer
      width={600}
      title={<p className="font-poppins text-lg">New Conatct</p>}
      open={openDrawer}
      onClose={() => setOpen(false)}
    >
      <div className="font-poppins ">
        <p className="text-xs  text-gray-500 leading-6">
          When someone reaches out to you,they become a contact in your
          account.You can create companies and associate contacts with them.
        </p>
        <div>
          <Form
            onFinish={(val) => handleSubmit(val)}
            form={form}
            labelCol={{ span: 24 }}
          >
            <div className="grid grid-cols-1 bg-gray-100 rounded-md shadow shadow-gray-200 p-4 mt-4 md:grid-cols-2 place-items-center gap-x-2">
              <Form.Item
                name={"name"}
                rules={[{ required: true, message: "Full name is required" }]}
                className="w-full"
                label={makeLabel("Full Name")}
              >
                <CommonInput placeHolder="Full name" />
              </Form.Item>
              <Form.Item
                name={"title"}
                className="w-full"
                label={makeLabel("Title")}
              >
                <CommonInput placeHolder="Title" />
              </Form.Item>{" "}
              <Form.Item
                name={"email"}
                rules={[
                  { required: true, message: "Email is required" },
                  {
                    pattern: emailPattern,
                    message: "Please enter a valid email",
                  },
                ]}
                className="w-full"
                label={makeLabel("Email")}
              >
                <CommonInput placeHolder="Email" />
              </Form.Item>{" "}
              <Form.Item
                name={"phone"}
                rules={[
                  { required: true, message: "Mobile is required" },
                  {
                    pattern: mobilePattern,
                    message: "Please enter a valid mobile",
                  },
                ]}
                className="w-full"
                label={makeLabel("Work Phone")}
              >
                <CommonInput placeHolder="Mobile" />
              </Form.Item>{" "}
              <Form.Item
                name={"address"}
                rules={[{ required: true, message: "Address is required" }]}
                className="w-full"
                label={makeLabel("Address")}
              >
                <CommonInput placeHolder="Address" />
              </Form.Item>{" "}
              <Form.Item
                name={"tags"}
                className="w-full"
                label={makeLabel("Tags")}
              >
                <Select options={tagss} placeHolder="Tags" />
              </Form.Item>{" "}
              <Form.Item
                name={"about"}
                className="w-full"
                label={makeLabel("Description")}
              >
                <CommonInput placeHolder="Description" />
              </Form.Item>
            </div>
            <div className="flex px-2 mt-4 justify-start items-center gap-x-2">
              <Form.Item>
                <Button
                  onClick={() => form.resetFields()}
                  type="primary"
                  className="bg-blue-600 text-white font-semibold rounded-none border-none hover:border-none"
                >
                  Reset
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={btnLoading}
                  htmlType="submit"
                  type="primary"
                  className="bg-red-600 text-white font-semibold rounded-none border-none hover:border-none"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};

export default NewConatct;
