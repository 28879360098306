import { Get, getAPIUrl, Post } from "../../Apiservices/ApiMethods";
import { AuthEndpoint } from "./AuthEndpoint";

export const userLogin = (data, param = "") => {
  const url = getAPIUrl(AuthEndpoint.userLogin, param);
  return Post(url, data, false);
};
export const vendorLogin = (data, param = "") => {
  const url = getAPIUrl(AuthEndpoint.vendorLogin, param);
  return Post(url, data, false);
};
export const vendorVerifyTwoFA = (data, param = "") => {
  const url = getAPIUrl(AuthEndpoint.vendorVerifyTwoFA, param);
  return Post(url, data,false);
};
export const expiryNotify = (param = "") => {
  const url = getAPIUrl(AuthEndpoint.expiryNotify, param);
  return Get(url, param);
};
export const userLogout = (param = "") => {
  const url = getAPIUrl(AuthEndpoint.userlogout, param);
  return Post(url);
};
export const Logout = (data = null, param = "") => {
  const url = getAPIUrl(AuthEndpoint.userlogout, param);
  return Post(url, data);
};

export const getAuthCode = (param = "") => {
  const url = getAPIUrl(AuthEndpoint.authorizationCodeURL, param);
  return Get(url, param);
};

export const resetUserPassApi = (data, param = "") => {
  const url = getAPIUrl(AuthEndpoint.resetUserPassword, param);
  return Post(url, data);
};
export const resetVendorPassApi = (data, param = "") => {
  const url = getAPIUrl(AuthEndpoint.resetVendorPassord, param);
  return Post(url, data);
};

export const createNewLink = (param = "") => {
  const url = getAPIUrl(AuthEndpoint.createNewLink, param);
  return Post(url);
};
