import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const useUserDetailStore = create()(
  devtools(
    persist(
      (set) => ({
        profileDetail: {},
        userProfile:null,
        vendorProfile:null,
        setProfileDetail:(data) => set({ profileDetail: data }),
        getUserProfile: (data) => set({ userProfile: data }),
        getVendorProfile: (data) => set({ vendorProfile: data }),

      }),
      { name: "userStore" }
    )
  )
);
