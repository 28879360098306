import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getLocalStorageItem } from "../Utils";
import ProjectLayout from "../ProjectLayout/Index";
import { Skeleton } from "antd";
import Errorboundry from "./Errorboundry";

function ProtectedVendorAuth() {
  const isAuthenticated = () => {
    const user = getLocalStorageItem("accessToken");
    return user;
  };

  return isAuthenticated() ? (
    <>
      {" "}
      <ProjectLayout>
        <Suspense
          fallback={
            <>
              <Skeleton active className="mt-4" />
              <Skeleton active className="mt-4" />
              <Skeleton active className="mt-4" />
              <Skeleton active className="mt-4" />
            </>
          }
        >
          <Errorboundry>
            <Outlet />
          </Errorboundry>
        </Suspense>
      </ProjectLayout>
    </>
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
}
export default ProtectedVendorAuth;
