import {
  FormDataPost,
  Get,
  getAPIUrl,
  Post,
} from "../../../Apiservices/ApiMethods";
import { ProfileEndpoint } from "./ProfileEndpoint";

export const ProfileDetail = (param = "") => {
  const url = getAPIUrl(ProfileEndpoint.ProfileDetail, param);
  return Get(url);
};
export const ProfileDetailUser = (param = "") => {
  const url = getAPIUrl(ProfileEndpoint.profileDetailUser, param);
  return Get(url);
};
export const setSelectPictureVendor = (data, param = "") => {
  const url = getAPIUrl(ProfileEndpoint.getSelectPictureVendor, param);
  return FormDataPost(url, data, true);
};
export const setSelectPictureUser = (data, param = "") => {
  const url = getAPIUrl(ProfileEndpoint.getSelectPictureUser, param);
  return FormDataPost(url, data, true);
};
export const userSignature = (data, param = "") => {
  const url = getAPIUrl(ProfileEndpoint.userSignature, param);
  return Post(url, data);
};
export const vendorSignature = (data, param = "") => {
  const url = getAPIUrl(ProfileEndpoint.vendorSignature, param);
  return Post(url, data);
};
export const getUserSignature = (param = "") => {
  const url = getAPIUrl(ProfileEndpoint.getUserSign, param);
  return Get(url, param);
};
export const getVendorSign = (param = "") => {
  const url = getAPIUrl(ProfileEndpoint.getVendorSign, param);
  return Get(url, param);
};
export const getAuthCode = (param = "") => {
  const url = getAPIUrl(ProfileEndpoint.authorizationCodeURL, param);
  return Get(url, param);
};

export const googleAuth = (param = "") => {
  const url = getAPIUrl(ProfileEndpoint.googleAuthorization, param);
  return Get(url, param);
};