import { getLocalStorageItem } from "../Utils";
const Role=getLocalStorageItem("Role")
export const CommonEndpoint = {
  Leaduser: `/user/validateUserRole?userId=${
    getLocalStorageItem("userDetails")?.userId
  }`,
  createMail: `${
    Role === "user" ? "user" : Role === "vendor" ? "vendor" : ""
  }/ticketService/send-mail?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`,
  createContact: `/${
    Role === "user" ? "user" : Role === "vendor" ? "vendor" : ""
  }/addNewContact?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`,
  editContact: `${
    Role === "user" ? "user" : Role === "vendor" ? "vendor" : ""
  }/UpdateContact?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`,
  getPictureUploadVendor: `/api/upload/profile_picture?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }&role=vendor`,
  getPictureUploadUser: `/api/upload/profile_picture?userId=${
    getLocalStorageItem("userDetails")?.userId
  }&role=user`,
  commonSearch: `/common/search?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`,
  ticketCountByStatus:`/common/getTicketCountbyStatus?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }&role=vendor`,
  ticketCountByAgent:`/common/getTicketCountbyAgent?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`
};
