export const AuthEndpoint = {
  userLogin: "user/login",
  vendorLogin: "vendor/login-vendor",
  vendorVerifyTwoFA: `vendor/vendor-2FA`,
  expiryNotify: `vendor/expiryNotify`,
  authorizationCodeURL: `token/authorization-code-url?email=`,
  resetUserPassword: `/user/ResetUserPassword?token=`,
  resetVendorPassord: `/vendor/ResetVendorPassword?token=`,
  createNewLink: `/user/ResetUserPasswordURL?email=`,
  vendorLogout: "vendor/log-out",
  userlogout: "user/logout",
};
