import { Button, Form } from "antd";
import React, { useState } from "react";
import { dispatchtoast, makeLabel } from "../../Utils";
import CommonInput from "../../CommonComponents/CommonInput";
import { resetUserPassApi, resetVendorPassApi } from "./AuthFun";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const getRole = param.get("role");
  const validatePassword = (rule, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("password")) {
      return Promise.reject("The password do not match");
    }
    return Promise.resolve();
  };
  const handleReset = (val) => {
    setBtnLoading(true);
    try {
      let res;
      res =
        getRole === "user"
          ? resetUserPassApi(
              { password: val.password },
              location.pathname.split("/")[2].split("=")[1]
            )
          : resetVendorPassApi(
              { password: val.password },
              location.pathname.split("/")[2].split("=")[1]
            );
      if (res.status) {
        dispatchtoast(res.message);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBtnLoading(false);
    }
  };
  return (
    <div className="flex justify-center bg-[#F7F6FF] font-poppins items-center h-screen">
      <div
        style={{ boxShadow: " 0px 0px 35px 0px #9AA1AB26" }}
        className="bg-white rounded-md m-4 w-[500px] px-8 py-8  "
      >
        <div className="text-secondary flex justify-center items-center w-1/2  m-auto border border-secondary px-4 py-2 rounded-lg">
          <p>Helpdesk</p>
        </div>

        <p className="mt-4 text-center font-semibold text-[#3D4465]">
          Reset Password
        </p>
        <Form form={form} onFinish={(val) => handleReset(val)}>
          <Form.Item
            name={"password"}
            rules={[
              {
                required: true,
                message: "Password Is Required",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters",
              },
            ]}
            labelCol={{ span: 24 }}
            label={makeLabel("password")}
          >
            <CommonInput placeHolder="Password" />
          </Form.Item>
          <Form.Item
            name={"confirm"}
            rules={[
              {
                required: true,
                message: "Confirm Password Is Required",
              },
              { validator: validatePassword },
            ]}
            dependencies={["password"]}
            labelCol={{ span: 24 }}
            label={makeLabel("Confirm Password")}
          >
            <CommonInput placeHolder="Confirm Password" />
          </Form.Item>
          <Form.Item>
            <Button
              loading={btnLoading}
              type="primary"
              htmlType="submit"
              style={{ color: "white" }}
              className="font-poppins w-full mt-4  text-white font-bold rounded-lg bg-secondary"
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
        <p
          className="text-center font-poppins cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          Click here for Login
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
