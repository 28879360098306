import PublicAuth from "./PublicAuth";
import Login from "../Views/Auth/Login";
import TwoFa from "../Views/Auth/TwoFa";
import ResetPassword from "../Views/Auth/ResetPassword";
import GenerateLink from "../Views/Auth/GenerateLink";

export const PublicRoutes = () => {
  return [
    {
      element: <PublicAuth />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "/twoFactorAuthentication",
          element: <TwoFa />,
        },
        {
          path: '/resetPass/:token',
          element: <ResetPassword />,
        },
        {
          path: '/generateLink/:email',
          element: <GenerateLink />,
        },
      ],
    },
  ];
};
