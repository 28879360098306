import { HashRouter, useRoutes } from "react-router-dom";
import "./App.css";
// import 'react-slideshow-image/dist/styles.css';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { PrivateAuth } from "./Routes/PrivateRoutes";
import { PublicRoutes } from "./Routes/PublicRoutes";
import "react-toastify/dist/ReactToastify.css";
import { dispatchtoast, getLocalStorageItem, ToastContainerMsg } from "./Utils";
import { PrivateVendorAuth } from "./Routes/PrivateVendorRoutes";
import { useEffect, useState } from "react";
import { SOCKET_URL } from "./Apiservices/Axios";
import { io } from "socket.io-client";

function App() {
  const [socket, setSocket] = useState(null);

  const UserRouting = [...PublicRoutes(), ...PrivateAuth()];
  const VendorRouting = [
    ...PublicRoutes(),
    ...PrivateAuth(),
    ...PrivateVendorAuth(),
  ];
  const Role = getLocalStorageItem("Role");
  const UserRouteConfig = () => useRoutes(UserRouting);
  const VendorRouteConfig = () => useRoutes(VendorRouting);
  useEffect(() => {
    const socketInstance = io(SOCKET_URL); // Replace with your server URL

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket && Role === "vendor") {
      socket.on("connect", () => {
        console.log("Socket connected:", socket.connected);
      });
      socket.on("vendorNotification", (data) => {
        const userDetails = getLocalStorageItem("userDetails");
        if (userDetails && userDetails.vendorId) {
          dispatchtoast("New mail arrived");
          var beepsound = new Audio("./beep.mp3");
          beepsound.play();
        }
      });

      // Emit 'vendorNotification' event only if the user is logged in
      const userDetails = getLocalStorageItem("userDetails");
      if (userDetails && userDetails.vendorId) {
        socket.emit("vendorNotification", userDetails.vendorId);
      }
    }
    //eslint-disable-next-line
  }, [socket]);
  return (
    <>
      <HashRouter>
        {Role === "user" ? <UserRouteConfig /> : <VendorRouteConfig />}
      </HashRouter>
      <ToastContainerMsg />
    </>
  );
}

export default App;
