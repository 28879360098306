import { lazy } from "react";
import ProtectedVendorAuth from "./PrivateVendorAuth";
const Plans = lazy(() => import("../Views/Vendor/Plans/Plans"));
const PlanHistory = lazy(() => import("../Views/Vendor/Plans/PlanHistory"));
const PlanCheckout = lazy(() => import("../Views/Vendor/Plans/PlanCheckout"));
const Agents = lazy(() => import("../Views/Vendor/Settings/Agents/Agents"));
const Settings = lazy(() => import("../Views/Vendor/Settings/Settings"));
const AgentForm = lazy(() =>
  import("../Views/Vendor/Settings/Agents/AgentForm")
);
const Groups = lazy(() => import("../Views/Vendor/Settings/Groups/Groups"));
const Tags = lazy(() => import("../Views/Vendor/Settings/Tags/Tags"));

export const PrivateVendorAuth = () => {
  return [
    {
      element: <ProtectedVendorAuth />,
      children: [
        {
          path: "/vendor/plans",
          element: <Plans />,
        },
        {
          path: "/vendor/plan-history",
          element: <PlanHistory />,
        },
        {
          path: "/vendor/plan-checkout",
          element: <PlanCheckout />,
        },
        {
          path: "/vendor/settings",
          element: <Settings />,
        },
        {
          path: "/vendor/agents",
          element: <Agents />,
        },
        {
          path: "/vendor/agent-form",
          element: <AgentForm />,
        },
        {
          path: "/vendor/groups",
          element: <Groups />,
        },
        {
          path: "/vendor/tags",
          element: <Tags />,
        },
        // ERROR ROUTES ENDED
      ],
    },
  ];
};
