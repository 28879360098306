import { getLocalStorageItem } from "../../../Utils";

export const ProfileEndpoint = {
  ProfileDetail: `vendor/vendorById?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`,
  profileDetailUser: `user/fetchUserById?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }&userId=${getLocalStorageItem("userDetails")?.userId}`,
  getSelectPictureVendor: `/api/upload?id=${
    getLocalStorageItem("userDetails")?.vendorId
  }&role=vendor`,
  getSelectPictureUser: `/api/upload?id=${
    getLocalStorageItem("userDetails")?.userId
  }&role=user`,
  userSignature: `user/update-user-signature?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`,
  vendorSignature: `vendor/update-vendor-signature?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`,
  getUserSign: `user/get-user-signature?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }&userId=${getLocalStorageItem("userDetails")?.userId}`,
  getVendorSign: `vendor/get-vendor-signature?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`,
  authorizationCodeURL: `token/authorization-code-url?email=`,
  googleAuthorization:`token/gAuth/generate-auth-url?email=`
};
