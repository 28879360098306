import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { emailPattern, makeLabel, setLocalStorageItem } from "../../Utils";
import CommonInput from "../../CommonComponents/CommonInput";
import { userLogin, vendorLogin } from "./AuthFun";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [loginType, setLoginType] = useState("Vendor");
  const navigate = useNavigate();
  const handleLogin = (val) => {
    setBtnLoading(true);
    if (loginType !== "User") {
      userLogin(val)
        .then((res) => {
          if (res.status) {
            setLocalStorageItem("accessToken", res.token);
            setLocalStorageItem("Role", "user");
            setLocalStorageItem("mailId", res.data.vendorEmail);
            setLocalStorageItem("userDetails", res.data);
            navigate("/dashboard");
            window.location.reload();
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setBtnLoading(false));
    } else if (loginType !== "Vendor") {
      const payload = {
        vendorEmail: val.userEmail,
        password: val.password,
      };
      setBtnLoading(true);
      vendorLogin(payload)
        .then((res) => {
          if (res.status) {
            setLocalStorageItem("accessToken", res.token);
            setLocalStorageItem("Role", "vendor");
            setLocalStorageItem("mailId", val?.userEmail);
            setLocalStorageItem("userDetails", res.response);
            setLocalStorageItem(
              "AuthorizedEmail",
              res.response.AuthorizedEmail
            );
            navigate("/twoFactorAuthentication", {
              state: {
                qr: res.response.qrCode,
                email: res.response.Email,
              },
            });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setBtnLoading(false));
    }
  };
  return (
    <>
      <div className="flex justify-center bg-[#F7F6FF] font-poppins items-center h-screen">
        <div
          style={{ boxShadow: " 0px 0px 35px 0px #9AA1AB26" }}
          className="bg-white rounded-md m-4 w-[500px] px-8 py-8  "
        >
          <div className="text-secondary flex justify-center items-center w-1/2  m-auto border border-secondary px-4 py-2 rounded-lg">
            <p>Helpdesk</p>
          </div>
          <p className="mt-4 text-center font-semibold text-[#3D4465]">
            Login As {loginType === "User" ? "Vendor" : "User"}
          </p>
          <p className="mt-4 text-center font-semibold text-[#3D4465]">
            Sign in your account
          </p>
          <Form onFinish={(val) => handleLogin(val)}>
            <Form.Item
              name={"userEmail"}
              rules={[
                {
                  required: true,
                  message: "Email Is Required",
                },
                {
                  pattern: emailPattern,
                  message: "Please Enter Valid Email",
                },
              ]}
              labelCol={{ span: 24 }}
              label={makeLabel("Email")}
            >
              <CommonInput placeHolder="Username or email address..." />
            </Form.Item>
            <Form.Item
              name={"password"}
              rules={[
                {
                  required: true,
                  message: "Password Is Required",
                },
              ]}
              labelCol={{ span: 24 }}
              label={makeLabel("Password")}
            >
              <Input.Password
                className="rounded-none  hover:border border-secondary hover:border-secondary hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                placeHolder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={btnLoading}
                type="primary"
                iconPosition="end"
                htmlType="submit"
                style={{ color: "white" }}
                prefix="jj"
                className="font-poppins w-full mt-4  text-white font-bold rounded-lg bg-secondary"
              >
                Sign Me In
              </Button>
            </Form.Item>
          </Form>
          <p
            className="text-center text-secondary font-semibold cursor-pointer"
            onClick={() =>
              loginType === "Vendor"
                ? setLoginType("User")
                : setLoginType("Vendor")
            }
          >
            Login As {loginType}
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
