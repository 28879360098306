import { getLocalStorageItem } from "../../../Utils";
const Role = getLocalStorageItem("Role");
export const TicketEndpoint = {
  optionTypes: "get-options/options?option_name=",
  usersList: `${
    Role === "user" ? "user" : Role === "vendor" ? "vendor" : ""
  }/user-list?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`,
  userAssignedMail: `${
    Role === "user" ? "user" : Role === "vendor" ? "vendor" : ""
  }/assigned-mail-list?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`,
  changeTicketDetail: `${
    Role === "user" ? "user" : Role === "vendor" ? "vendor" : ""
  }/ticketService/change-ticket-details?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`,
  changeTicketAssignee: `${
    Role === "user" ? "user" : Role === "vendor" ? "vendor" : ""
  }/assigned-ticket?vendorId=${
    getLocalStorageItem("userDetails")?.vendorId
  }`,
};
