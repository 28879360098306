import React, { useState } from "react";
import { Images } from "../ImageController";
import { IoReload } from "react-icons/io5";

const Errorboundry = ({ children }) => {
  const [has, sethas] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorLocation, setErrorLocation] = useState("");
  window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
    sethas(true);
    setErrorMsg(errorMsg);
    setErrorLocation(url);
  };
  if (has) {
    return (
      <div className="flex flex-col justify-center items-center gap-y-3 font-poppins">
        <img
          loading="lazy"
          src={Images.manThinking}
          className="w-[400px]"
          alt="manthinking"
        />
        <p className="font-semibold text-lg text-primary">
          Something Went wrong
        </p>
        <p className="text-sm text-center text-gray-500">{errorMsg}</p>
        <p className="text-sm text-center text-gray-500">{errorLocation}</p>
        <button
          onClick={() => {
            sethas(false);
            window.location.reload();
          }}
          className="bg-secondary px-4 py-2 rounded-md text-white font-semibold flex justify-center items-center gap-x-3 "
        >
          <IoReload /> Reload the page
        </button>
      </div>
    );
  } else {
    return children;
  }
};

export default Errorboundry;
