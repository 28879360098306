import { Get, getAPIUrl, Post, Put } from "./ApiMethods";
import { CommonEndpoint } from "./CommonEndpoint";

export const LeadUser = (param = "") => {
  const url = getAPIUrl(CommonEndpoint.Leaduser, param);
  return Get(url, param);
};
export const createMail = (data, param = "") => {
  const url = getAPIUrl(CommonEndpoint.createMail, param);
  return Post(url, data);
};

export const createContact = (data, param = "") => {
  const url = getAPIUrl(CommonEndpoint.createContact, param);
  return Post(url, data);
};
export const editContact = (data, param = "") => {
  const url = getAPIUrl(CommonEndpoint.editContact, param);
  return Put(url, data);
};
export const getPictureUploadVendor = (param = "") => {
  const url = getAPIUrl(CommonEndpoint.getPictureUploadVendor, param);
  return Get(url, param);
};
export const getPictureUploadUser = (param = "") => {
  const url = getAPIUrl(CommonEndpoint.getPictureUploadUser, param);
  return Get(url, param);
};
export const commonSearch = (completeUrl, param = "") => {
  const url = getAPIUrl(completeUrl, param);
  return Get(url, param);
};
export const getTicketCountStatus = (param = "") => {
  const url = getAPIUrl(CommonEndpoint.ticketCountByStatus, param);
  return Get(url, param);
};
export const getTicketCountAgent = ( param = "") => {
  const url = getAPIUrl(CommonEndpoint.ticketCountByAgent, param);
  return Get(url, param);
};
