import { AutoComplete } from "antd";
import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { getLocalStorageItem, trimString } from "../Utils";
import { useNavigate } from "react-router-dom";
import { commonSearch } from "../Apiservices/CommonFun";

const Searchbar = () => {
  const [options, setoptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const Role = getLocalStorageItem("Role");
  const userDetails = getLocalStorageItem("userDetails");

  useEffect(() => {
    let delayDebounce;
    if (searchTerm !== "") {
      delayDebounce = setTimeout(() => {
        let url =
          Role === "user" && !userDetails?.isEmployee
            ? `/user/search?vendorId=${
                getLocalStorageItem("userDetails")?.vendorId
              }&searchString=${searchTerm}&userId=${userDetails?.userId}`
            : Role === "user" && userDetails?.isEmployee
            ? `/user/search?vendorId=${
                getLocalStorageItem("userDetails")?.vendorId
              }&searchString=${searchTerm}`
            : `/vendor/search?vendorId=${
                getLocalStorageItem("userDetails")?.vendorId
              }&searchString=${searchTerm}`;
        commonSearch(url)
          .then((res) => {
            if (res.status) {
              setoptions(
                res.data.map((i, index) => {
                  return {
                    label: (
                      <div
                        onClick={() => {
                          navigate(`/openticket`, {
                            state: {
                              data: i,
                              keyType: "normal",
                              status: [],
                              userData: [],
                            },
                          });
                          setSearchTerm("");
                        }}
                        className="flex  p-[6px] text-[#4F4F4F] font-inter mt-4 justify-between items-center flex-wrap"
                      >
                        <div className="flex justify-start items-center gap-x-3">
                          <p>#{i?.ticket_id} &nbsp; &nbsp; |</p>
                          <p>{trimString(i.subject)}</p>
                        </div>
                        <div>
                          <p>{i.sender_mail_id}</p>
                        </div>
                      </div>
                    ),
                    value: index,
                  };
                })
              );
            }
          })
          .catch((err) => console.log(err));
      }, 400);
    }
    return () => clearTimeout(delayDebounce);
    //eslint-disable-next-line
  }, [searchTerm]);
  return (
    <>
      <AutoComplete
        suffixIcon={<FaSearch />}
        options={options}
        value={searchTerm}
        onSearch={(val) => setSearchTerm(val)}
        rootClassName="rounded-md"
        style={{ borderRadius: "10px !important" }}
        placeholder="Search"
        className="border-none rounded-md searchbar hover:border-none w-full focus:border-none h-10 font-poppins font-semibold"
      />
    </>
  );
};

export default Searchbar;
